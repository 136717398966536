"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.route = exports.match = exports.history = exports.location = void 0;
var PropTypes = __importStar(require("prop-types"));
exports.location = PropTypes.shape({
    hash: PropTypes.string.isRequired,
    key: PropTypes.string,
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    state: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.bool,
        PropTypes.number,
        PropTypes.object,
        PropTypes.string,
    ]),
});
exports.history = PropTypes.shape({
    action: PropTypes.oneOf(['PUSH', 'REPLACE', 'POP']).isRequired,
    block: PropTypes.func.isRequired,
    createHref: PropTypes.func.isRequired,
    go: PropTypes.func.isRequired,
    goBack: PropTypes.func,
    goForward: PropTypes.func,
    back: PropTypes.func,
    forward: PropTypes.func,
    index: PropTypes.number,
    length: PropTypes.number,
    listen: PropTypes.func.isRequired,
    location: exports.location.isRequired,
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
});
exports.match = PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
});
var routeShape = {
    path: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    exact: PropTypes.bool,
    strict: PropTypes.bool,
    sensitive: PropTypes.bool,
    component: PropTypes.func,
};
routeShape.routes = PropTypes.arrayOf(PropTypes.shape(routeShape));
exports.route = PropTypes.shape(routeShape);
exports.default = {
    location: exports.location,
    history: exports.history,
    match: exports.match,
    route: exports.route,
};
